<template>
  <div ref="pie" class="pie"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Pie",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: [Number, String],
      default: 450,
    },
    extend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        legend: {
          top: "center",
          right: "20",
          orient: "vertical",
          align: "left",
          icon: "circle",
          itemWidth: 9,
          itemHeight: 9,
          itemGap: 15,
          textStyle: {
            color: "#999",
          },
        },
        color: ["#619AF1", "#FF9776", "#6EDBA7"],
        title: {
          text: "满分分值",
          top: "45%",
          left: "39%",
          textAlign: "center",
          textVerticalAlign: "center",
          textStyle: {
            color: "#333",
            fontSize: 14,
            align: "center",
            fontWeight: 300,
          },
          subtext: "150",
          subtextStyle: {
            color: "#333",
            fontSize: 18,
            align: "center",
            width: "50px",
            fontWeight: 500,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["40%", "center"],
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: "inside",
              color: "#fff",
            },
            labelLine: {
              show: false,
            },

            data: [],
          },
        ],
      },
    };
  },
  watch: {
    data(val) {
      this.initChart(val);
    },
  },
  methods: {
    initChart(val) {
      let chart = echarts.init(this.$refs.pie, null, { devicePixelRatio: 2.5 });
      this.option.title.subtext = val.totalScore;
      const { easyScore, mediumScore, hardScore, totalScore } = val;
      this.option.series[0].data = [
        {
          value: val.easyScore,
          percent: this.getPercent(easyScore, totalScore),
          name: "简单题",
        },
        {
          value: val.mediumScore,
          percent: this.getPercent(mediumScore, totalScore),
          name: "中等题",
        },
        {
          value: val.hardScore,
          percent: this.getPercent(hardScore, totalScore),
          name: "难题",
        },
      ];
      let data = { ...this.option, ...this.extend };
      chart.setOption(data, true);
      setTimeout(() => {
        window.onresize = function () {
          chart.resize({ height: null, width: null });
        };
      }, 200);
    },
    getPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return 0;
      }
      return total <= 0 ? "0%" : Math.round((num / total) * 10000) / 100.0;
    },
  },
};
</script>
<style lang="scss" scoped>
.pie {
  width: 100%;
  height: 100%;
}
</style>
