import request from "@/core/services/axios";
export function getnewgaokaoreporttopinfo(params) {
  // 新高考顶部数据
  return request({
    url: `/report/statnewgaokaoreport/getnewgaokaoreporttopinfo`,
    method: `get`,
    params: params,
  });
}
export function teachclass(params) {
  // 新高教学班
  return request({
    url: `/report/statnewgaokaoreport/getngkreportapdistribution/teachclass`,
    method: `get`,
    params: params,
  });
}
export function getAdministrativeClass(params) {
  // 新高行政班
  return request({
    url: `/report/statnewgaokaoreport/getngkreportapdistribution/class`,
    method: `get`,
    params: params,
  });
}
export function getAplist(params) {
  // 新高考基础统计-赋分等级分布-划线标准
  return request({
    url: `/report/statnewgaokaoreport/getngkreportapdistribution/aplist`,
    method: `get`,
    params: params,
  });
}
export function getngkreportbasicindicator(params) {
  // 新高考基础统计-基础指标汇总
  return request({
    url: `/report/statnewgaokaoreport/getngkreportbasicindicator`,
    method: `get`,
    params: params,
  });
}
export function getstatscoretypesetting(params) {
  // 获取统计分数类型设置
  return request({
    url: `/report/stat/getstatscoretypesetting`,
    method: `get`,
    params: params,
  });
}
export function setstatscoretypesetting(params) {
  // 保存统计分数类型设置
  return request({
    url: `/report/stat/setstatscoretypesetting`,
    method: `post`,
    params: params,
  });
}
export function getstatsubjectapinfo(params) {
  // apCalculateType 0-使用比例计算 1-使用分数计算
  // apSource 赋分方式 0-系统划线赋分 1-导入赋分结果 2-使用原始分直接赋分
  // total 参考人数
  //er// apTotal 参与赋分人数
  // zeroTotal 缺考/零分人数
  // 获取统计科目的赋分设置
  return request({
    url: `/report/stat/getstatsubjectapinfo`,
    method: `get`,
    params: params,
  });
}
export function getdefaultapproportion(params) {
  // 统计科目赋分设置-恢复默认比例
  return request({
    url: `/report/stat/getdefaultapproportion`,
    method: `get`,
    params: params,
  });
}
export function setstatsubjectapproportion(params) {
  // 使用比例分级-应用
  return request({
    url: `/report/stat/setstatsubjectapproportion`,
    method: `post`,
    data: params,
  });
}
export function setstatsubjectaprealintervalmin(params) {
  // 使用分数分级-应用
  return request({
    url: `/report/stat/setstatsubjectaprealintervalmin`,
    method: `post`,
    data: params,
  });
}
export function setapexcludezero(params) {
  // 除缺考与零分考生（保存零分不参与赋分）
  return request({
    url: `/report/stat/setapexcludezero`,
    method: `get`,
    params: params,
  });
}
export function getreusestatsubjectaplist(params) {
  // 统计科目赋分设置-选用历史比例
  return request({
    url: `/report/stat/getreusestatsubjectaplist`,
    method: `get`,
    params: params,
  });
}
export function apscorepage(params) {
  // 获取统计赋分结果分页接口
  return request({
    url: `/report/stat/apscorepage`,
    method: `post`,
    data: params,
  });
}
export function getstatclassoption(params) {
  // 获取统计的班级下拉列表V2 行政班级
  return request({
    url: `/report/stat/getstatclassoption/v2`,
    method: `get`,
    params: params,
  });
}
export function getstatteachclassoption(params) {
  // 获取指定统计科目的教学班下拉列表
  return request({
    url: `/report/stat/getstatteachclassoption`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function exportapscore(params) {
  // 导出统计赋分结果榜单
  return request({
    url: `/report/stat/exportapscore`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function exportapscoredetails(params) {
  // 导出统计赋分结果详细榜单
  return request({
    url: `/report/stat/exportapscoredetails`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
