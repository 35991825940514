<template>
  <div ref="radar" class="radar"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Radar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 350,
    },
    title: {
      type: String,
      default: "",
    },
    hasTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      examType: this.$route.query.examType,
    };
  },
  watch: {
    data(value) {
      if (value.val) {
        this.initChart(value);
      }
    },
  },
  methods: {
    initChart({ val, isSchoolDisplayReportUnionAverageScore }) {
      let series = [];
      if (this.examType == 1) {
        let data = [
          {
            value: val.map((item) => item.classScoreRate),
            name: "班级",
            color: "#7AB5EF",
          },
          {
            value: val.map((item) => item.schoolScoreRate),
            name: "学校",
            color: "#6EDBA7",
          },
        ];

        if (isSchoolDisplayReportUnionAverageScore == 1) {
          data.push({
            value: val.map((item) => item.unionScoreRate),
            name: "整体",
            color: "#F57574",
          });
        }
        series = [
          {
            name: "",
            type: "radar",
            data: [...data],
          },
        ];
      } else {
        series = [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: val.map((item) => item.classScoreRate),
                name: "班级",
                color: "#7AB5EF",
              },
              {
                value: val.map((item) => item.gradeScoreRate),
                name: "年级",
                color: "#6EDBA7",
              },
            ],
          },
        ];
      }
      const chart = echarts.init(this.$refs.radar, null, {
        devicePixelRatio: 2.5,
      });
      let option = {
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        },

        legend: {
          data:
            this.examType == 1 ? ["班级", "学校", "整体"] : ["班级", "年级"],
          orient: "vertical",
          right: "0", //可设定图例在左、右、居中
          top: "center", //可设定图例在上、下、居中
          align: "left",
          itemHeight: 2,
          itemWidth: 10,
          icon: "roundRect",
        },
        radar: {
          indicator: val.map((item) => {
            return {
              name: item.questionOrder,
              max: 100,
            };
          }),
          splitArea: {
            show: false,
          },
        },
        series: [...series],
      };
      chart.setOption(option, true);
      setTimeout(() => {
        window.addEventListener("resize", () => {
          chart.resize();
        });
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.radar {
  width: 100%;
  height: 100%;
}
</style>
