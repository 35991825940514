import { getStore } from "@/core/util/store";

// teacherRoleType 1-任课教师 2-班主任 3-备课组长 4-年级组长 5-校领导
var roleTypeArr = {
  联考报告: [4, 5],
  新高考基础统计: [4, 5],
  校级报告: [4, 5],
  教研报告: [3, 4, 5],
  班主任报告: [2, 4, 5],
  任课教师报告: [1, 3, 4, 5],
  试卷讲评: [1, 2, 3, 4, 5],
  学生报告: [1, 2, 3, 4, 5],
};
function roleValidate(val) {
  let accountRole = JSON.parse(sessionStorage.getItem("accountRole"));
  let data = [];
  if (accountRole) {
    data = accountRole;
  }
  const { roleId } = getStore({
    name: "userInfo",
  });
  if (["班主任报告", "任课教师报告", "试卷讲评"].includes(val)) {
    if (this.examType == 1 && this.topData.statClass != 1) {
      return false;
    }
  }
  if (roleId === 5) {
    let finaly = data.filter((item) => {
      return roleTypeArr[val].includes(item);
    });
    return finaly.length > 0;
  } else {
    return true;
  }
}
export { roleValidate };
