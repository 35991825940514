import request from "@/core/services/axios";
export function overallanalysis(params) {
  // 总体情况分析
  return request({
    url: `/report/overallanalysis/list`,
    method: "get",
    params: params,
  });
}
export function questionanaly(params) {
  // 试题数据分析
  return request({
    url: `/report/statunionexamquestionanaly/questionanaly`,
    method: "get",
    params: params,
  });
}
export function segment(params) {
  // 总分分数段分布
  return request({
    url: `/report/overallanalysis/segment`,
    method: "get",
    params: params,
  });
}
export function subjectSegment(params) {
  // 单科分数段分布 - 分数段分布表
  return request({
    url: `/report/overallanalysis/subject/segment`,
    method: "get",
    params: params,
  });
}
export function segmentDetail(params) {
  // 单科分数段分布 - 详细分段分布图
  return request({
    url: `/report/overallanalysis/subject/segment/detail`,
    method: "get",
    params: params,
  });
}
export function totalscoreindexcontrast(params) {
  // 总分各项指标对比
  return request({
    url: `/report/schoolcontrast/totalscoreindexcontrast`,
    method: "get",
    params: params,
  });
}
export function subjectscoreindexcontrast(params) {
  // 单科各项指标对比
  return request({
    url: `/report/schoolcontrast/subjectscoreindexcontrast`,
    method: "get",
    params: params,
  });
}
export function gradeRankHeader(params) {
  // 联考 - 整体分析 - 成绩榜表头
  return request({
    url: `/report/overallanalysis/score/list/header`,
    method: "get",
    params: params,
  });
}
export function gradeRankList(params) {
  // 联考 - 整体分析 - 成绩榜数据
  return request({
    url: `/report/overallanalysis/score/list`,
    method: "get",
    params: params,
  });
}
export function levelGradeRankHeader(params) {
  // 联考 - 整体分析 - 等级成绩榜表头
  return request({
    url: `/report/overallanalysis/score/grade/header`,
    method: "get",
    params: params,
  });
}
export function levelGradeRankList(params) {
  // 联考 - 整体分析 - 等级成绩榜
  return request({
    url: `/report/overallanalysis/score/grade/list`,
    method: "get",
    params: params,
  });
}
export function summary(params) {
  // 各项指标对比汇总
  return request({
    url: `/report/schoolcontrast/indexcontrast/summary`,
    method: "get",
    params: params,
  });
}
export function ratiolist(params) {
  // 获取比率列表
  return request({
    url: `/report/schoolcontrast/get/ratiolist`,
    method: "get",
    params: params,
  });
}
export function objectivequestionanaly(params) {
  // 选择题数据分析
  return request({
    url: `/report/statunionexamquestionanaly/objectivequestionanaly`,
    method: "get",
    params: params,
  });
}
export function subjectivechoicequestionanaly(params) {
  // 非选择题试题分析
  return request({
    url: `/report/statunionexamquestionanaly/subjectivequestionanaly`,
    method: "get",
    params: params,
  });
}
export function optionquestionanaly(params) {
  // 选做题作答统计
  return request({
    url: `/report/statunionexamquestionanaly/optionquestionanaly`,
    method: "get",
    params: params,
  });
}
export function overallanalysisExport(params) {
  // 联考 - 总体情况分析 - excel导出
  return request({
    url: `/report/overallanalysis/export`,
    method: "get",
    params: params,
  });
}
export function exportSegment(params) {
  // 联考 - 总分分数段分布 - excel导出
  return request({
    url: `/report/overallanalysis/export/segment`,
    method: "get",
    params: params,
  });
}
export function exportScore(params) {
  // 联考 - 下载总分成绩榜 - excel导出
  return request({
    url: `/report/overallanalysis/export/score`,
    method: "get",
    params: params,
  });
}
export function subjectSegmentExport(params) {
  // 联考 - 单科分数段分布 - excel导出
  return request({
    url: `/report/overallanalysis/export/subject/segment`,
    method: "get",
    params: params,
  });
}
export function indexcontrastClass(params) {
  // 班级各项指标对比
  return request({
    url: `/report/schoolcontrast/indexcontrast/class`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparison(params) {
  // 常用指标各校对比
  return request({
    url: `/report/statunionexamquestionanaly/schoolindicatorcomparison`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparison(params) {
  // 常用指标班级对比
  return request({
    url: `/report/statunionexamquestionanaly/classindicatorcomparison`,
    method: "get",
    params: params,
  });
}
export function scoresegmentContrast(params) {
  // 分数段对比
  return request({
    url: `/report/schoolcontrast/scoresegment/contrast`,
    method: "get",
    params: params,
  });
}
export function questionscoredistribution(params) {
  // 试题得分详细分布
  return request({
    url: `/report/statunionexamquestionanaly/questionscoredistribution`,
    method: "get",
    params: params,
  });
}
export function totalscoretop(params) {
  // 总分前N名对比
  return request({
    url: `/report/schoolcontrast/totalscoretop/contrast`,
    method: "get",
    params: params,
  });
}
export function paperreliabilityanaly(params) {
  // 试卷信度统计
  return request({
    url: `/report/statunionexampaperanaly/paperreliabilityanaly`,
    method: "get",
    params: params,
  });
}
export function questionsummaryanaly(params) {
  // 试题汇总分析-试题汇总分析
  return request({
    url: `/report/statunionexampaperanaly/questionsummaryanaly`,
    method: "get",
    params: params,
  });
}
export function questionsummaryanalydifficulty(params) {
  // 试题汇总分析-难度分布
  return request({
    url: `/report/statunionexampaperanaly/questionsummaryanalydifficulty`,
    method: "get",
    params: params,
  });
}
export function questionsummaryanalydiscrimination(params) {
  // 试题汇总分析-区分度分布
  return request({
    url: `/report/statunionexampaperanaly/questionsummaryanalydiscrimination`,
    method: "get",
    params: params,
  });
}
export function subjectscoretop(params) {
  // 单科前N名对比
  return request({
    url: `/report/schoolcontrast/subjectscoretop/contrast`,
    method: "get",
    params: params,
  });
}
export function subjectqualityContrast(params) {
  // 学科质量对比分析
  return request({
    url: `/report/schoolcontrast/subjectquality/contrast`,
    method: "get",
    params: params,
  });
}
export function exportgradescore(params) {
  // 联考 - 下载等级成绩榜 - excel导出
  return request({
    url: `/report/overallanalysis/export/grade/score`,
    method: "get",
    params: params,
  });
}
export function exportAllUnion(params) {
  // 联考 - 导出所有报告
  return request({
    url: `/report/statoneexamexport/union/exportAll`,
    method: "post",
    data: params,
  });
}
export function importtoschool(params) {
  // 联考-导入到学校
  return request({
    url: `/report/stat/importtoschool`,
    method: "post",
    data: params,
  });
}
export function getstatschooloption(params) {
  // 获取联考统计的学校列表
  return request({
    url: `/report/stat/getstatschooloption`,
    method: "get",
    params: params,
  });
}
export function difficultydistribution(params) {
  // 难度等级分布
  return request({
    url: `/report/statunionexampaperanaly/difficultydistribution`,
    method: "get",
    params: params,
  });
}
export function discriminationdistribution(params) {
  // 区分度等级分布
  return request({
    url: `/report/statunionexampaperanaly/discriminationdistribution`,
    method: "get",
    params: params,
  });
}
export function typicalpaper(params) {
  // 典型试卷
  return request({
    url: `/report/statunionexamfeaturepaper/typicalpaper`,
    method: "get",
    params: params,
  });
}
export function paperquestionlist(params) {
  // 高分小题-试题列表
  return request({
    url: `/report/statunionexamfeaturepaper/paperquestionlist`,
    method: "get",
    params: params,
  });
}
export function highscorequestionheader(params) {
  // 高分小题-表头
  return request({
    url: `/report/statunionexamfeaturepaper/highscorequestionheader`,
    method: "get",
    params: params,
  });
}
export function highscorequestiondata(params) {
  // 高分小题-分页数据
  return request({
    url: `/report/statunionexamfeaturepaper/highscorequestiondata`,
    method: "post",
    data: params,
  });
}
export function overallanaly(params) {
  // 总体情况
  return request({
    url: `/report/statunionexamonlineanaly/overallanaly`,
    method: "get",
    params: params,
  });
}
export function schooltotalonlineanaly(params) {
  // 各校总分上线情况
  return request({
    url: `/report/statunionexamonlineanaly/schooltotalonlineanaly`,
    method: "get",
    params: params,
  });
}
export function schoolsubjectonlineanaly(params) {
  // 各校单科上线情况
  return request({
    url: `/report/statunionexamonlineanaly/schoolsubjectonlineanaly`,
    method: "get",
    params: params,
  });
}
export function schoolallsubjectonlineanaly(params) {
  // 各校各学科上线情况
  return request({
    url: `/report/statunionexamonlineanaly/schoolallsubjectonlineanaly`,
    method: "get",
    params: params,
  });
}
export function gradescorelistheader(params) {
  // 年级成绩榜-表头
  return request({
    url: `/report/statunionexamschoolanaly/gradescorelistheader`,
    method: "get",
    params: params,
  });
}
export function gradescorelistdatapage(params) {
  // 年级成绩榜-数据
  return request({
    url: `/report/statunionexamschoolanaly/gradescorelistdatapage`,
    method: "post",
    data: params,
  });
}
export function gradelevellistheader(params) {
  // 年级等级榜-表头
  return request({
    url: `/report/statunionexamschoolanaly/gradelevellistheader`,
    method: "get",
    params: params,
  });
}
export function gradelevellistdatapage(params) {
  // 年级等级榜-数据
  return request({
    url: `/report/statunionexamschoolanaly/gradelevellistdatapage`,
    method: "post",
    data: params,
  });
}
export function getstatareasettinglist(params) {
  // 获取统计区域设置列表
  return request({
    url: `/report/stat/getstatareasettinglist`,
    method: "get",
    params: params,
  });
}
export function insertstatareasetting(params) {
  // 增加统计区域设置
  return request({
    url: `/report/stat/insertstatareasetting`,
    method: "post",
    data: params,
  });
}
export function savestatareasetting(params) {
  // 修改统计区域设置
  return request({
    url: `/report/stat/savestatareasetting`,
    method: "post",
    data: params,
  });
}
export function deletestatareasetting(params) {
  // 删除统计区域设置
  return request({
    url: `/report/stat/deletestatareasetting`,
    method: "get",
    params: params,
  });
}
export function clearstatareasetting(params) {
  // 清空统计区域设置
  return request({
    url: `/report/stat/clearstatareasetting`,
    method: "get",
    params: params,
  });
}
export function downloadstatschool(params) {
  // 下载学校信息
  return request({
    url: `/report/stat/downloadstatschool`,
    method: "get",
    params: params,
  });
}
export function importstatareasetting({ id, file }) {
  // 导入统计区域设置
  return request({
    url: `/report/stat/importstatareasetting?id=${id}`,
    method: "post",
    data: file,
  });
}
export function exportstatareasetting(params) {
  // 导出统计区域设置
  return request({
    url: `/report/stat/downloadstatareasetting`,
    method: "get",
    params: params,
  });
}
export function setstatexcludeschoolsetting(params) {
  // 保存不参与统计的学校
  return request({
    url: `/report/stat/setstatexcludeschoolsetting`,
    method: "post",
    data: params,
  });
}
export function importstatstudentincludeschool(params) {
  // 导入不参与学校统计学生
  return request({
    url: `/report/stat/importstatstudentincludeschool?id=${params.id}`,
    method: "post",
    data: params.file,
  });
}
export function clearstatstudentincludeschool(params) {
  // 清空不参与学校统计设置
  return request({
    url: `/report/stat/clearstatstudentincludeschool`,
    method: "get",
    params: params,
  });
}
export function setstatstudentincludeschool(params) {
  // 保存学生不参与学校统计设置
  return request({
    url: `/report/stat/setstatstudentincludeschool`,
    method: "get",
    params: params,
  });
}
export function getstatquestiontypesetting(params) {
  // 获取统计大题设置
  return request({
    url: `/report/stat/getstatquestiontypesetting`,
    method: "get",
    params: params,
  });
}
export function setstatquestiontypesetting(params) {
  // 保存统计大题设置
  return request({
    url: `/report/stat/setstatquestiontypesetting`,
    method: "post",
    data: params,
  });
}
export function getunionreporttopinfo(params) {
  // 获取联考报告顶部数据
  return request({
    url: `/report/statunionunionreport/getunionreporttopinfo`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportoverallcompetitivescoredistribution(params) {
  // 联考报告-整体竞争力-成绩分布
  return request({
    url: `/report/statunionunionreport/getunionreportoverallcompetitivescoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportoverallcompetitiveschooloverallcompetitive(
  params
) {
  // 联考报告-整体竞争力-校整体竞争力
  return request({
    url: `/report/statunionunionreport/getunionreportoverallcompetitiveschooloverallcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportclasscompetitivesubjectbalanceanalysis(params) {
  // 联考报告-班级竞争力-学科均衡分析
  return request({
    url: `/report/statunionunionreport/getunionreportclasscompetitivesubjectbalanceanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportclasscompetitiveclassratio(params) {
  // 联考报告-班级竞争力-各班比率分布
  return request({
    url: `/report/statunionunionreport/getunionreportclasscompetitiveclassratio`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportclasscompetitiveclassonline(params) {
  // 联考报告-班级竞争力-各班上线情况
  return request({
    url: `/report/statunionunionreport/getunionreportclasscompetitiveclassonline`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreporthighscorestudentdistribution(params) {
  // 联考报告-高分群体
  return request({
    url: `/report/statunionunionreport/getunionreporthighscorestudentdistribution`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportsubjectcompetitive(params) {
  // 联考报告-学科竞争力
  return request({
    url: `/report/statunionunionreport/getunionreportsubjectcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportdevelopanalysis(params) {
  // 联考报告-发展性分析
  return request({
    url: `/report/statunionunionreport/getunionreportdevelopanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getunionreportsimple(params) {
  // 获取联考报告简单图表
  return request({
    url: `/report/statunionreportinfo/getunionreportsimple`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function exportAnswerSheet(params) {
  // 导出答题卡
  return request({
    url: `/report/statoneexamexport/exportAnswerSheet/union`,
    method: "post",
    data: params,
  });
}
export function exportoneexamoriginalquestiondata(params) {
  // 导出原始数据
  return request({
    url: `/report/statoneexamexport/exportoneexamoriginalquestiondata/union`,
    method: "post",
    data: params,
  });
}
