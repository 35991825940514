<template>
  <div ref="barLine" class="barLine"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "BarLine",
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chartObj: {},
      examType: this.$route.query.examType,
    };
  },
  watch: {
    source(val) {
      this.initChart(val);
    },
  },
  methods: {
    initChart({ xAxis, lineData, barData, barDataOri, lineDataOri }) {
      let series = [];
      if (this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0) {
        // 赋分统计
        series.push({
          name: "本校原始分",
          type: "bar",
          color: "#2474ED",
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: "rgba(0,0,0,0.45)",
              fontSize: 9,
            },
          },
          data: barDataOri,
        });
      }
      if (barData) {
        series.push({
          name:
            this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0
              ? "本校赋分"
              : "学校",
          type: "bar",
          barMaxWidth: 40,
          color:
            this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0
              ? "#85BAED"
              : "#2474ED",
          label: {
            show: true,
            position: "top",
            textStyle: {
              // color: "inherit",
              color: "rgba(0,0,0,0.45)",
              fontSize: 9,
            },
          },
          data: barData,
        });
      }

      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        if (this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0) {
          // 赋分统计
          series.push({
            name: "整体原始分",
            type: "line",
            color: "#FF8857",
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "rgba(0,0,0,0.45)",
              },
            },
            data: lineDataOri,
          });
        }
        series.push({
          name:
            this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0
              ? "整体赋分"
              : "年级",
          type: "line",
          color: "#F57574",
          label: {
            show: false,
            position: "top",
            textStyle: {
              color: "rgba(0,0,0,0.45)",
            },
          },
          data: lineData,
        });
      }
      let options = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return val ? Math.abs(val) : "-";
          },
        },
        height: "auto",
        width: "auto",
        legend: { itemGap: 10 },
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#999",
                fontSize: 12,
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#999",
                fontSize: 12,
              },
            },
          },
        ],
        grid: {
          left: "40px",
          right: "10px",
        },
        series: [...series],
      };
      this.chartObj = echarts.init(this.$refs.barLine, null, {
        devicePixelRatio: 2.5,
      });
      this.chartObj.setOption(options, true);
      let that = this;
      setTimeout(() => {
        window.onresize = function () {
          that.chartObj.resize({ height: null, width: null });
        };
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.barLine {
  width: 100%;
  height: 100%;
}
</style>
