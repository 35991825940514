<template>
  <div ref="chart" class="charts"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (this.data) {
            this.initChart();
          }
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data) {
        this.initChart();
      }
    });
  },
  methods: {
    initChart() {
      let charts = echarts.init(this.$refs.chart, null, {
        devicePixelRatio: 2.5,
      });

      charts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        ...this.data,
      });
      setTimeout(() => {
        window.onresize = function () {
          charts.resize({ height: null, width: null });
        };
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 100%;
}
</style>
