<template>
  <div class="tagContainer">
    <div class="tag">
      <div v-if="!defineTag">{{ tagName }}</div>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    defineTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tagName: "",
    };
  },
  created() {
    const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
    this.tagName = userMsg.content.schoolName;
    if (this.$route.query.schoolName) {
      this.tagName = this.$route.query.schoolName;
    }
    if (this.$route.query.code) {
      const params = require("js-base64").decode(this.$route.query.code);
      const { examType, schoolName } = JSON.parse(params);
      if (examType == 1) {
        this.tagName = schoolName;
      }
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.tagContainer {
  position: absolute;
  top: 0;
  left: 0;
  .tag {
    width: fit-content;
    height: 40px;
    background-color: #2474ed;
    color: #fff;
    text-align: center;
    line-height: 38px;
    padding: 0 20px;
    font-size: 14px;
    position: relative;
    text {
      height: auto !important;
    }
    &::after {
      content: "";
      width: 0;
      height: 0;

      border-top: 40px solid #2474ed;
      border-right: 50px solid transparent;
      position: absolute;
      top: 0;
      left: 100%;
    }
  }
}
</style>
