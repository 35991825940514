<template>
  <div class="reportDetail">
    <div class="topCard">
      <tag define-tag>{{ schoolName }}</tag>
      <div class="title-box">
        <div class="title">
          <div v-if="ruleId > 0" class="new-exam-tag">新高考</div>
          {{ topData.name }}
          <div
            :class="['sub-tag', topData.type == 99 ? 'otherTitle' : 'general']"
          >
            {{ getExamType(topData.type) }}
          </div>
        </div>
        <!-- <el-button type="primary" plain size="mini" @click="exportPDF"
          >导出PDF</el-button
        > -->
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <!-- <exportBtn
        :request-url="requestUrl"
        :params="{ id: $route.query.id }"
        :text="'导出全部报表'"
        btn-type="primary"
        is-big-size
      > -->
      <div class="sub-title">
        <div>
          年级：<span v-if="level == 2">初</span
          ><span v-if="level == 3">高</span>{{ topData.year }}级
        </div>
        <div>时间：{{ topData.examDate }}</div>
      </div>
      <!-- </exportBtn> -->

      <div v-if="ruleId > 0 && scoreType == 1" class="new-dashboard">
        <div
          :class="[
            'box',
            'normal',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <div class="item border-item">
            <div class="item-title">
              {{ topData.eleccombCount }}<span>个</span>
            </div>
            <div>选考组合</div>
          </div>
          <div class="item">
            <div class="item-title">
              {{ topData.classCount }}<span>个</span>
            </div>
            <div>行政班</div>
          </div>
          <div class="item">
            <div class="item-title">
              {{ topData.studentCount }}<span>名</span>
            </div>
            <div>考生</div>
          </div>
          <div class="item">
            <div class="item-title">{{ topData.statTotal }}<span>名</span></div>
            <div>统计人数</div>
          </div>
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          class="vertical-text"
        >
          最 高 分
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          :class="[
            'box',
            'other',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <template v-if="topData.hasPhysics == 1">
            <div
              :class="[
                'item',
                topData.historyMaxScoreOri && topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]"
            >
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>物理类</div>
            </div>
          </template>
          <template v-if="topData.hasHistory == 1">
            <div class="item">
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.historyMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.historyMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>历史类</div>
            </div>
          </template>
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          class="vertical-text"
          style="background: #f3fcf8"
        >
          平 均 分
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          :class="[
            'box',
            'success',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <template v-if="topData.hasPhysics == 1">
            <div
              :class="[
                'item',
                topData.historyMaxScoreOri && topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]"
            >
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.physicsAverageScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.physicsAverageScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>物理类</div>
            </div>
          </template>
          <template v-if="topData.hasHistory == 1">
            <div class="item">
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.historyAverageScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.historyAverageScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>历史类</div>
            </div>
          </template>
        </div>
      </div>
      <template v-else>
        <div class="dashboard">
          <div class="examinfo-box">
            <div class="exam-sub">
              <span>{{ topData.subjectCount }}个<br />学科</span>
            </div>
            <span class="info-line"></span>
            <div class="fl">
              <p>
                <i>{{ topData.classCount }}</i
                >个班级
              </p>
              <p>
                <i>{{ topData.studentCount }}</i
                >名学生
              </p>
            </div>
          </div>
          <div class="scorebox bg-lightblue">
            <p class="ft-blue">{{ topData.averageScore }}</p>
            <span>{{ examType == 1 ? "校平均分" : "平均分" }}</span>
          </div>
          <div class="scorebox bg-lightorange ml30">
            <p class="ft-orange">{{ topData.highestScore }}</p>
            <span> {{ examType == 1 ? "校最高分" : "最高分" }}</span>
          </div>
          <ul
            v-if="examType != 1"
            class="rankingbox bg-lightgreen ml30 avgshow"
          >
            <li>
              <p>{{ topData.averageClassNum || 0 }}</p>
              <span>均分序NO.1</span>
            </li>
            <li>
              <p>{{ topData.onlineClassNum || 0 }}</p>
              <span>{{ topData.onlineName }}NO.1</span>
            </li>
            <li>
              <p>{{ topData.ratioClassNum || 0 }}</p>
              <span>{{ topData.ratioName }}NO.1</span>
            </li>
          </ul>
          <ul v-else class="rankingbox bg-lightgreen ml30 avgshow">
            <li>
              <p>{{ topData.overAverageRate || 0 }} %</p>
              <span>超均率</span>
            </li>
            <li>
              <p>{{ topData.deviationFromMean || 0 }}</p>
              <span>离均差</span>
            </li>
            <li v-for="(item, index) in topData.ratios" :key="index">
              <p>{{ item.value || 0 }} %</p>
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div v-if="topData.year" class="charts-box">
      <!-- isInclude 本校是否参与统计 -->
      <div
        v-if="
          roleValidate('联考报告') && examType == 1 && topData.isInclude == 1
        "
        ref="barLine"
        v-lazyChart="{ fn: getunionreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">联考报告</div>
          <div
            class="detail"
            @click="
              routerTo(
                isNewExam
                  ? '/academic/new-exam/union-report'
                  : '/academic/reportStat/unionReport'
              )
            "
          >
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <unionBarLine
            :source="unionReportData"
            :secend-name="'整体'"
            :title="'各参考学科平均分对比图'"
          />
          <div class="chart-title">各参考学科平均分对比图</div>
        </div>
      </div>
      <div
        v-if="roleValidate('校级报告')"
        ref="barLine"
        v-lazyChart="{ fn: getschoolreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">校级报告</div>
          <div
            class="detail"
            @click="
              routerTo(
                '/academic/new-exam/school-report',
                '/academic/reportStat/schoolReport'
              )
            "
          >
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <bar-line :source="schoolReportData" :title="'各班级平均分对比图'" />
          <div class="chart-title">各班级平均分对比图</div>
        </div>
      </div>
      <div
        v-if="roleValidate('教研报告')"
        ref="teachingReport"
        v-lazyChart="{ fn: getresearchreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">教研报告</div>
          <div
            class="detail"
            @click="
              routerTo(
                '/academic/new-exam/teaching-report',
                '/academic/reportStat/teachingReport'
              )
            "
          >
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <div class="chart">
            <div class="research-indicators">
              <div class="indicators-box bg-lightblue">
                <p class="ft-blue">
                  {{ ((teachingReportData.difficulty || 0) / 100).toFixed(4) }}
                </p>
                <span>难度</span>
              </div>
              <div class="indicators-box bg-lightgreen">
                <p class="ft-green">
                  {{ (teachingReportData.discrimination || 0 / 1).toFixed(4) }}
                </p>
                <span>区分度</span>
              </div>
            </div>
            <pie :data="teachingReportData" :extend="pieExtend" :height="250" />
          </div>
          <div class="chart-title">{{ teachingReportData.subjectName }}</div>
        </div>
      </div>
      <div
        v-if="roleValidate('班主任报告')"
        ref="headMaster"
        v-lazyChart="{ fn: getbanzhurenreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">班主任报告</div>
          <div
            class="detail"
            @click="
              routerTo(
                '/academic/new-exam/director-report',
                '/academic/reportStat/headReport'
              )
            "
          >
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <bar-line :source="headMasterData" />
          <div class="chart-title">
            {{ `${headMasterData.classNum || ""}班各学科得分率对比分析` }}
          </div>
        </div>
      </div>
      <div
        v-if="roleValidate('任课教师报告')"
        ref="teacher"
        v-lazyChart="{ fn: getrenkejiaoshireportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">任课教师报告</div>
          <div
            class="detail"
            @click="
              routerTo(
                '/academic/new-exam/teacher-report',
                '/academic/reportStat/teacherReport'
              )
            "
          >
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <radar
            :has-total="topData.isSchoolDisplayReportUnionAverageScore == 1"
            :data="{
              val: teacherReportData[
                examType == 1
                  ? 'questions'
                  : 'renKeJiaoShiReportSimpleQuestionVOs'
              ],
              isSchoolDisplayReportUnionAverageScore:
                examType == 1
                  ? topData.isSchoolDisplayReportUnionAverageScore
                  : null,
            }"
          />
          <div class="chart-title">
            {{
              `${teacherReportData.subjectName || ""} ${
                teacherReportData.classNum || ""
              }班 小题得分率`
            }}
          </div>
        </div>
      </div>
      <div
        v-if="roleValidate('试卷讲评')"
        ref="paperReport"
        v-lazyChart="{ fn: getpaperreviewreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">试卷讲评</div>
          <div class="detail" @click="paperReportDetail">
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content">
          <progressChart
            :data="
              examType == 1
                ? paperReportData.answers
                : paperReportData.paperReviewReportSimpleAnswerVOS
            "
            :class-num="paperReportData.classNum"
            :right-answer="paperReportData.rightAnswer"
          />
          <div class="chart-title">
            {{ paperReportData.subjectName }} {{ paperReportData.classNum }}班
            {{ paperReportData.questionOrder }}题
          </div>
        </div>
      </div>
      <div
        v-if="roleValidate('学生报告')"
        ref="student"
        v-lazyChart="{ fn: getstudentreportsimpleData }"
        class="chart-item"
      >
        <div class="item-header">
          <div class="title">学生报告</div>
          <div class="detail" @click="studentReportDetail">
            查看报告<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="content studentContent">
          <div></div>
          <charts v-if="studentLineOptions" :data="studentLineOptions" />
          <heat
            v-else
            :source="studentReportData"
            :item-height="35"
            :size="'small'"
            :grid-top="'5px'"
            :head-height="30"
          />
          <div class="chart-title">
            {{
              `${studentReportData.classNum || ""}班${
                studentReportData.name || ""
              }击败率`
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tag from "./components/tag.vue";
import barLine from "./charts/barLine.vue";
import unionBarLine from "./charts/unionBarLine.vue";
// import bar from "./charts/bar.vue";
import pie from "./charts/pie.vue";
import radar from "./charts/radar.vue";
import progressChart from "./charts/progress.vue";
import charts from "./charts/charts.vue";
import heat from "./charts/heat.vue";
import { getnewgaokaotopinfo } from "@/core/api/academic/common";
// import exportBtn from "@/views/academic/components/exportBtn.vue";
import { unionGetnewgaokaotopinfo } from "@/core/api/newExamAcademic/union/index";
import {
  downloadstatpdfreport,
  gettopinfo,
  getschoolreportsimple,
  getbanzhurenreportsimple,
  getstudentreportsimple,
  getresearchreportsimple,
  getrenkejiaoshireportsimple,
  getpaperreviewreportsimple,
} from "@/core/api/academic/report";
import { getunionreportsimple } from "@/core/api/academic/union";
import { fileDownloadByUrl } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import { examTypeOptions } from "@/core/util/constdata";
import { roleValidate } from "@/core/util/roleValidate";
import { getstatscoretypesetting } from "@/core/api/academic/newExam";
import {
  Uniongettopinfo,
  Uniongetschoolreportsimple,
  Uniongetresearchreportsimple,
  Uniongetbanzhurenreportsimple,
  Uniongetrenkejiaoshireportsimple,
  Uniongetpaperreviewreportsimple,
  Uniongetstudentreportsimple,
  newExamGetschoolreportsimple,
} from "@/core/api/academic/unionReport";
export default {
  name: "ReportDetail",
  components: {
    tag,
    barLine,
    // exportBtn,
    pie,
    radar,
    progressChart,
    // bar,
    unionBarLine,
    heat,
    charts,
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.fullPath == "/academic/statistics" ||
      from.fullPath == "/academic/reportStat"
    ) {
      next(() => {
        localStorage.setItem("routerFrom", from.fullPath);
      });
    } else {
      next();
    }
  },
  data() {
    return {
      examId: null,
      scoreType: null,
      schoolName: "",
      requestUrl: null,
      topData: {},
      roleObj: {},
      schoolReportData: {},
      headMasterData: {},
      studentReportSimpleStatVOS: [],
      studentReportData: {},
      examType: this.$route.query.examType,
      statId: this.$route.query.id,
      schoolId: this.$route.query.schoolId,
      ruleId: "",
      resStudent: {
        classNum: "01",
        name: "小明",
        studentReportSimpleStatVOS: [
          {
            name: "期末联考",
            studentReportSimpleStatSubjectVOS: [
              {
                name: "语文",
                scoreStd: "120",
              },
            ],
          },
          {
            name: "月考",
            studentReportSimpleStatSubjectVOS: [
              {
                name: "数学",
                scoreStd: "130",
              },
            ],
          },
        ],
      },
      teachingReportData: {},
      unionReportData: {},
      level: null,
      teacherReportData: {},
      paperReportData: {},
      newExamData: {},
      lineData: [],
      roleId: null,
      teacherRoleType: null,
      studentLineOptions: null,
      pieExtend: {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          formatter: function (params) {
            let str =
              "难度：" +
              params.data.name +
              "<br />" +
              "占比：" +
              params.data.value +
              "(" +
              params.data.percent +
              "%)";
            return str;
          },
        },
      },
      isNewExam: false,
    };
  },
  mounted() {
    const { id, level, examId, scoreType, ruleId } = this.$route.query;
    this.isNewExam = ruleId > 0 && scoreType == 1;
    this.statId = id;
    this.examId = examId;
    this.level = level;

    this.ruleId = this.$route.query.ruleId;
    let examType = this.$route.query.examType;

    if (examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      let { schoolName } = getStore({
        name: "userInfo",
      });
      this.schoolName = schoolName ? schoolName : "";
    }
    this.requestUrl =
      scoreType == 1 && ruleId > 0
        ? "/report/statnewgaokaostudentreport/export/all"
        : "/report/statstudentreport/export/all";
    this.getstatscoretypesettingData();
  },
  methods: {
    async getunionreportsimpleData(loadingInstance, io) {
      const res = await getunionreportsimple({
        id: this.statId,
        viewSchoolId: this.schoolId,
      });
      const apSubjects = res.data.data.subjects.filter(
        (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
      );
      const notApSubjects = res.data.data.subjects.filter((item) =>
        [1, 2, 3, 4, 8].includes(item.subjectId)
      );
      const resultData = [...notApSubjects, ...apSubjects];
      let xAxis = resultData.map((item) => item.subjectName);
      let barData = resultData.map((item) => {
        if (this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0) {
          return ![1, 2, 3, 4, 8].includes(item.subjectId)
            ? item.schoolAverageScore
            : null;
        }
        return item.schoolAverageScore;
      });
      const barDataOri = resultData.map((item) => item.schoolAverageScoreOri);
      let lineData = resultData.map((item) => {
        if (this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0) {
          return ![1, 2, 3, 4, 8].includes(item.subjectId)
            ? item.unionAverageScore
            : null;
        }
        return item.unionAverageScore;
      });
      const lineDataOri = resultData.map((item) => item.unionAverageScoreOri);
      this.unionReportData = {
        xAxis: xAxis,
        barData: barData,
        barDataOri: barDataOri,
        lineData: lineData,
        lineDataOri: lineDataOri,
      };
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.barLine);
      });
    },
    async getstatscoretypesettingData() {
      const res = await getstatscoretypesetting({
        id: this.statId,
      });
      this.scoreType = res.data.data;
      if (this.ruleId > 0 && this.scoreType == 1) {
        if (this.$route.query.examType == 1) {
          this.getNewExamUnionTopData();
        } else {
          this.getNewExamTopData();
        }
      } else {
        this.getTopData();
      }
    },
    async getNewExamUnionTopData() {
      const res = await unionGetnewgaokaotopinfo({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.topData = { ...res.data.data };
    },
    async getNewExamTopData() {
      const res = await getnewgaokaotopinfo({ id: this.statId });
      this.topData = res.data.data;
    },
    back() {
      const routerPaht = localStorage.getItem("routerFrom");
      this.$router.push(routerPaht);
    },
    roleValidate: roleValidate,
    async getresearchreportsimpleData(loadingInstance, io) {
      let res;
      if (this.examType == 1) {
        res = await Uniongetresearchreportsimple({
          id: this.statId,
          viewSchoolId: this.schoolId,
        });
      } else {
        res = await getresearchreportsimple({ id: this.statId });
      }
      this.teachingReportData = res.data.data;
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.teachingReport);
      });
    },
    async getpaperreviewreportsimpleData(loadingInstance, io) {
      try {
        let res;
        if (this.examType == 1) {
          res = await Uniongetpaperreviewreportsimple({
            id: this.statId,
            viewSchoolId: this.schoolId,
          });
        } else {
          res = await getpaperreviewreportsimple({ id: this.statId });
        }
        this.paperReportData = res.data.data;
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.paperReport);
        });
      } catch {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.paperReport);
        });
      }
    },
    paperReportDetail() {
      this.$router.push({
        path: "/academic/reportStat/paperReport",
        query: {
          name: this.topData.name,
          year: this.topData.year,
          examDate: this.topData.examDate,
          type: this.topData.type,
          id: this.statId,
          level: this.level,
          examId: this.examId,
          schoolName: this.schoolName,
          examType: this.$route.query.examType,
          schoolId: this.$route.query.schoolId,
        },
      });
    },
    studentReportDetail() {
      let url;
      if (this.ruleId > 0 && this.scoreType == 1) {
        url = "/academic/new-exam/student-rank";
      } else {
        url = "/academic/reportStat/student";
      }
      this.$router.push({
        path: url,
        query: {
          name: this.topData.name,
          year: this.topData.year,
          examDate: this.topData.examDate,
          type: this.topData.type,
          id: this.statId,
          level: this.level,
          schoolName: this.schoolName,
          examType: this.$route.query.examType,
          schoolId: this.$route.query.schoolId,
          ruleId: this.ruleId,
        },
      });
    },
    async getschoolreportsimpleData(loadingInstance, io) {
      let res;
      if (this.examType == 1) {
        if (this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0) {
          res = await newExamGetschoolreportsimple({
            id: this.statId,
            viewSchoolId: this.schoolId,
          });
        } else {
          res = await Uniongetschoolreportsimple({
            id: this.statId,
            viewSchoolId: this.schoolId,
          });
        }
      } else {
        res = await getschoolreportsimple({ id: this.statId });
      }

      let xAxis = res.data.data.schoolReportSimpleClassVOs.map(
        (item) => item.classNum
      );
      let barData = res.data.data.schoolReportSimpleClassVOs.map(
        (item) => item.average
      );

      let lineData = res.data.data.schoolReportSimpleClassVOs.map(
        () =>
          res.data.data[
            this.examType == 1 ? "schoolAverageScore" : "schoolAverage"
          ]
      );
      this.schoolReportData = {
        xAxis: xAxis,
        barData:
          (this.examType == 1 && this.topData.statClass == 1) ||
          this.examType != 1
            ? barData
            : null,
        lineData: lineData,
      };
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.schoolReportData.totalData =
          res.data.data.schoolReportSimpleClassVOs.map(
            () => res.data.data.unionAverageScore
          );
      }
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.barLine);
      });
    },
    async getrenkejiaoshireportsimpleData(loadingInstance, io) {
      try {
        let res;
        if (this.examType == 1) {
          res = await Uniongetrenkejiaoshireportsimple({
            id: this.statId,
            viewSchoolId: this.schoolId,
          });
        } else {
          res = await getrenkejiaoshireportsimple({ id: this.statId });
        }
        this.teacherReportData = res.data.data;
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.teacher);
        });
      } catch {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.teacher);
        });
      }
    },
    async getbanzhurenreportsimpleData(loadingInstance, io) {
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.headMaster);
      });
      let res;
      let key;
      if (this.examType == 1) {
        key = "subjects";
        res = await Uniongetbanzhurenreportsimple({
          id: this.statId,
          viewSchoolId: this.schoolId,
        });
      } else {
        key = "banZhuRenReportSimpleSubjectVOS";
        res = await getbanzhurenreportsimple({ id: this.statId });
      }
      this.headMasterData = {
        classNum: res.data.data.classNum,
        xAxis: res.data.data[key].map((item) => item.name),
        barData: res.data.data[key].map((item) => item.classScoreRate),
        lineData: res.data.data[key].map(
          (item) => item[this.examType ? "schoolScoreRate" : "gradeScoreRate"]
        ),
      };
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.headMasterData.totalData = res.data.data[key].map(
          (item) => item.unionScoreRate
        );
      }
    },
    async getstudentreportsimpleData(loadingInstance, io) {
      try {
        let res;
        if (this.examType == 1) {
          res = await Uniongetstudentreportsimple({
            id: this.statId,
            viewSchoolId: this.schoolId,
          });
        } else {
          res = await getstudentreportsimple({ id: this.statId });
        }

        if (
          (this.examType != 1 &&
            res.data.data.studentReportSimpleStatVOS[0]
              .studentReportSimpleStatSubjectVOS.length == 1) ||
          (res.data.data.stats && res.data.data.stats[0].subjects.length == 1)
        ) {
          this.studentReportData = {
            classNum: res.data.data.classNum,
            name: res.data.data.name,
          };
          let data =
            this.examType == 1
              ? res.data.data.stats
              : res.data.data.studentReportSimpleStatVOS;
          this.studentLineOptions = {
            grid: {
              containLabel: true,
              bottom: "10%",
            },
            xAxis: {
              type: "category",
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
              axisTick: {
                show: false,
              },
              data: data.map((item) => item.name),
            },
            series: [
              {
                data: data.map(
                  (item) =>
                    item[
                      this.examType == 1
                        ? "subjects"
                        : "studentReportSimpleStatSubjectVOS"
                    ][0].scoreStd || 0
                ),
                label: {
                  color: "inherit",
                  fontSize: 9,
                },
                type: "line",
              },
            ],
          };
        } else {
          this.studentLineOptions = null;
          let statKey =
            this.examType == 1 ? "stats" : "studentReportSimpleStatVOS";
          let subjectKey =
            this.examType == 1
              ? "subjects"
              : "studentReportSimpleStatSubjectVOS";
          let subjectArr = res.data.data[statKey].map((item) =>
            item[subjectKey].map((it) => it.name)
          );
          let xAxis = res.data.data[statKey].map((item) => item.name);
          subjectArr += "";
          let yAxis = [...new Set(subjectArr.split(","))];
          let data = res.data.data[statKey].map((item) =>
            item[subjectKey].map((it) => {
              return [
                xAxis.indexOf(item.name),
                yAxis.indexOf(it.name),
                it.scoreStd,
              ];
            })
          );
          let scoreArr = res.data.data[statKey].map((item) =>
            item[subjectKey].map((it) => it.scoreStd)
          );
          const finalyData = data.flat();
          this.studentReportData = {
            classNum: res.data.data.classNum,
            name: res.data.data.name,
            xAxis: xAxis,
            yAxis: yAxis.slice(0, 7),
            data: finalyData.slice(0, 7),
            max: Math.max.apply(null, scoreArr.join(",").split(",")),
            min: 0,
          };
        }

        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      } catch {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      }
    },

    routerTo(url, urlOri) {
      let query = { ...this.$route.query };
      if (this.ruleId > 0 && this.scoreType == 1) {
        this.$router.push({
          name: url,
          query: {
            id: this.statId,
            level: this.level,
            ruleId: this.ruleId,
            schoolName: this.schoolName,
            ...query,
          },
        });
      } else {
        let queryData;
        queryData = {
          id: this.statId,
          level: this.level,
          ruleId: this.ruleId,
          schoolName: this.schoolName,
          ...query,
        };
        this.$router.push({
          name: urlOri ? urlOri : url,
          query: { ...queryData, schoolName: this.schoolName },
        });
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    async exportPDF() {
      const params = { ...this.$route.query };
      const { id } = params;
      const res = await downloadstatpdfreport({ statId: id });
      if (res.data.data.url) {
        fileDownloadByUrl(res.data.data);
      }
    },
    async getTopData() {
      const { id, schoolId } = { ...this.$route.query };
      let res;
      if (this.examType == 1) {
        res = await Uniongettopinfo({ id: id, viewSchoolId: schoolId });
      } else {
        res = await gettopinfo({ id: id });
      }
      this.topData = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../newExamAcademic/new-exam.module.scss";

.reportDetail {
  margin: 0 auto;
  width: 1200px;

  .charts-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1220px;
    margin-right: -20px;

    // justify-content: space-between;
    .chart-item {
      width: 590px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.7);
      height: 350px;
      margin: 20px 20px 0 0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;
        max-height: 46px;
        min-height: 46px;

        .title {
          color: #333333;
          font-size: 16px;
        }

        .detail {
          font-size: 14px;
          color: #999999;
          text-align: right;
          cursor: pointer;

          i {
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 12px;
          }
        }
      }

      .content {
        width: 550px;
        height: 100%;
        margin: 5px auto;
        box-sizing: border-box;
        // padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .chart {
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: 100%;
        }

        .chart-title {
          padding: 0 20px;
          margin-top: 12px;
          font-size: 12px;
          text-align: center;
          color: #999;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.new-dashboard {
  display: flex;
  align-items: center;
  margin-top: 18px;

  .box {
    box-sizing: border-box;
    padding: 14px 12px;
    color: #3e4551;
    display: flex;
    height: 88px;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    flex: 1;
    justify-content: space-between;

    .item {
      flex: 1;
    }

    .item + .item {
      margin-left: 24px;
    }
  }

  .box + .box {
    margin-left: 24px;
  }

  .vertical-text + .box {
    margin-left: 8px;
  }
}

.flex-box {
  margin: 0 auto;
  flex: 1;

  .item {
    width: 100%;

    .flex {
      justify-content: center;

      .sub-item {
        flex: 1;
      }
    }
  }
}

.normal {
  background-color: #f3f8ff;

  .item-title {
    color: #2474ed;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #d2e2f9;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.other {
  background-color: #fff8f3;

  .item-title {
    color: #ff7a05;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #f3bd96;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.success {
  background-color: #f3fcf8;

  .item-title {
    color: #2bb472;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #c2e9d8;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-text {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  background: #fff8f3;
  border-radius: 8px;
  margin-left: 12px;
  height: 88px;
}

.sub-item {
  font-size: 12px;
  margin-bottom: 8px;

  .item-title {
    margin-bottom: 8px !important;
  }
}

.sub-border-item {
  border-right: 1px dashed #fee2ce;
  padding-right: 12px;
  box-sizing: border-box;
}

.sub-item + .sub-item {
  margin-left: 12px;
}

.otherTitle {
  background-color: #fff;
  border-color: #2474ed;
  color: #2474ed;
}

.new-exam-tag {
  color: #ff7a00;
  font-size: 14px;
  box-sizing: border-box;
  background: #fff8f3;
  border-radius: 4px;
  padding: 6px 8px;
  text-align: center;
  min-width: 0 !important;
  line-height: 1;
  margin-right: 4px;
}

ul {
  margin-bottom: 0;
}
</style>
