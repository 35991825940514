import request from "@/core/services/axios";
export const unionGetnewgaokaotopinfo = (params) => {
  // 获取新高考-统计报告详情页顶部数据
  return request({
    url: `/report/statunionreportinfo/getnewgaokaotopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getunionreporttopinfo = (params) => {
  // 获取新高考-统计报告详情页顶部数据
  return request({
    url: `/report/statunionnewgaokaounionreport/getunionreporttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const scoredistribution = (params) => {
  // 联考报告-成绩分布
  return request({
    url: `/report/statunionnewgaokaounionreport/scoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const eleccombcompetitive = (params) => {
  // 联考报告-组合竞争力
  return request({
    url: `/report/statunionnewgaokaounionreport/eleccombcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const subjectcompetitive = (params) => {
  //联考报告-学科竞争力
  return request({
    url: `/report/statunionnewgaokaounionreport/subjectcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const classcompetitive = (params) => {
  //联考报告-班级竞争力
  return request({
    url: `/report/statunionnewgaokaounionreport/classcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const subjectbalancecompare = (params) => {
  //联考报告-学科均衡性对比
  return request({
    url: `/report/statunionnewgaokaounionreport/subjectbalancecompare`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const highscorestudentdistribution = (params) => {
  //联考报告-高分群体
  return request({
    url: `/report/statunionnewgaokaounionreport/highscorestudentdistribution`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const ortdevelopanalysis = (params) => {
  //联考报告-发展性分析
  return request({
    url: `/report/statunionnewgaokaounionreport/ortdevelopanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
