<template>
  <div class="progress">
    <div class="legend">
      <div class="unit">
        <div class="point"></div>
        {{ classNum }}
      </div>
      <div class="class">
        <div class="point"></div>
        年级
      </div>
    </div>
    <div class="content-box">
      <div v-for="(item, index) in data" :key="index" class="progress-item">
        <div class="label">{{ item.name }}</div>
        <div class="com">
          <el-progress
            :stroke-width="12"
            :percentage="item.classProportion"
            :color="customColorMethod(item)"
            :format="format"
          ></el-progress>
          <div
            class="classLine"
            :style="{ left: `${item.gradeProportion}%` }"
          ></div>
        </div>
        <div class="num">{{ item.classCount }}人</div>
        <div class="percentage">占比{{ item.classProportion }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    classNum: {
      type: String,
      default: "",
    },
    rightAnswer: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      customColors: [
        { color: "#FD8585", percentage: 20 },
        { color: "#FF9776", percentage: 40 },
        { color: "#9E89FF", percentage: 60 },
        { color: "#7AB5EF", percentage: 80 },
        { color: "#3f87f4", percentage: 100 },
      ],
    };
  },

  mounted() {},
  methods: {
    format() {
      return "";
    },
    customColorMethod(item) {
      if (this.rightAnswer) {
        if (this.rightAnswer.length > 1) {
          return "#78B5EF";
        } else if (this.rightAnswer.length == 1) {
          const rightItem = this.data.find((it) => this.rightAnswer == it.name);
          if (this.rightAnswer == item.item) {
            return "#6EDBA7";
          } else if (item.classProportion > 30) {
            return "#FD8585";
          } else if (rightItem.classProportion >= item.classProportion) {
            return "FD8585";
          }
        }
      } else {
        return "#619AF1";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .unit {
      margin-right: 50px;
      display: flex;
      align-items: center;
      .point {
        background-color: #619af1;
        width: 24px;
        height: 12px;
        margin-right: 2px;
      }
    }
    .class {
      display: flex;
      align-items: center;
      .point {
        background-color: #ff9776;
        width: 2px;
        height: 12px;
        margin-right: 2px;
      }
    }
  }
  .content-box {
    overflow-y: auto;
    flex: 1;
    margin-bottom: 18px;
    .progress-item {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #8c8c8c;
      box-sizing: border-box;
      padding: 0 24px;
      margin-bottom: 15px;
      .label {
        margin-right: 24px;
        width: 28px;
      }
      .com {
        flex: 1;
        margin-right: 24px;
        position: relative;
        .classLine {
          position: absolute;
          background-color: #ff5233;
          width: 2px;
          height: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .num {
        margin-right: 24px;
        max-width: 50px;
        text-align: right;
      }
      .percentage {
        margin: 0;
        width: 80px;
        white-space: nowrap;
        text-align: left;
      }
    }
    & :last-child {
      margin: 0;
    }
  }
}
::-webkit-scrollbar-track-piece {
  //滚动条凹槽的颜色，还可以设置边框属性
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 9px;
  height: 9px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  //滚动条的设置
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
::v-deep .el-progress-bar__outer {
  background-color: #fafafa;
}
::v-deep .el-progress-bar__outer {
  border: 1px solid #efefef;
  height: 12px;
}
::v-deep .el-progress-bar__inner {
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
}
::v-deep .el-progress {
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.com {
  height: 12px;
}
</style>
