// 联考报告接口
import request from "@/core/services/axios";
// 校级报告
export function getschoolUnionReporttopinfo(params) {
  // 获取校级报告顶部数据
  return request({
    url: `/report/statunionschoolreport/getschoolreporttopinfo`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function downloadUnionschoolpdfreport(params) {
  // 下载校级报告PDF文档
  return request({
    url: `/report/statunionschoolreport/downloadschoolpdfreport`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function unionsubjectcompetitive(params) {
  // 校级报告-考情概述-校学科竞争力
  return request({
    url: `/report/statunionschoolreport/getschoolreportsummary/subjectcompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function unionScoredistribution(params) {
  // 校级报告-考情概述-成绩分布
  return request({
    url: `/report/statunionschoolreport/getschoolreportsummary/scoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UnionGetschoolreportclasscompetitive(params) {
  // 校级报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statunionschoolreport/getschoolreportclasscompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function unionClassscoredistribution(params) {
  // 校级报告-班级竞争力-班级成绩分布
  return request({
    url: `/report/statunionschoolreport/getschoolreportclasscompetitive/classscoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UnionGetschoolreportclassandcubject(params) {
  // 校级报告-班级&学科
  return request({
    url: `/report/statunionschoolreport/getschoolreportclassandcubject`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetSchoolReportSubjectContribution(params) {
  // 校级报告-学科贡献分析
  return request({
    url: `/report/statunionschoolreport/getSchoolReportSubjectContribution`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UnionCritical(params) {
  // 校级报告-重点关注学生-临界生
  return request({
    url: `/report/statunionschoolreport/getschoolreportimportstudent/critical`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniontracking(params) {
  // 校级报告-重点关注学生-跟踪生
  return request({
    url: `/report/statunionschoolreport/getschoolreportimportstudent/tracking`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Unionwave(params) {
  // 校级报告-重点关注学生-波动生
  return request({
    url: `/report/statunionschoolreport/getschoolreportimportstudent/wave`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniontoporbackward(params) {
  // 校级报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statunionschoolreport/getschoolreportimportstudent/toporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetschoolreportdevelopanalysis(params) {
  // 校级报告-发展性分析V2
  return request({
    url: `/report/statunionschoolreport/getschoolreportdevelopanalysis/v2`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
//------------------------教研报告------------------------
export function unionGetschoolreporttopinfo(params) {
  // 获取教研报告顶部数据
  return request({
    url: `/report/statunionresearchreport/getresearchreporttopinfo`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Unionscoredistribution(params) {
  // 教研报告-考情概述-成绩分布
  return request({
    url: `/report/statunionresearchreport/getresearchreportscoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalypaperquality(params) {
  // 教研报告-试卷分析-试卷命题质量
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalypaperquality`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestionquality(params) {
  // 教研报告-试卷分析-试题命题质量
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestionquality`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagquestionsingle(
  params
) {
  // 教研报告-试卷分析-试题诊断-题目-单道试题情况
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagquestionsingle`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagquestionscorerate(
  params
) {
  // 教研报告-试卷分析-试题诊断-题目-单道试题情况
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagquestionscorerate`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagquestionoverall(
  params
) {
  // 教研报告-试卷分析-试题诊断-题目-各小题得分率
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagquestionoverall`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagknowledgesingle(
  params
) {
  // 教研报告-试卷分析-试题诊断-知识点-单个知识点情况
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagknowledgesingle`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagknowledgescorerate(
  params
) {
  // 教研报告-试卷分析-试题诊断-知识点-各班各知识点得分率情况
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagknowledgescorerate`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportpaperanalyquestiondiagknowledgeoverall(
  params
) {
  // 教研报告-试卷分析-试题诊断-知识点-各知识点得分率
  return request({
    url: `/report/statunionresearchreport/getresearchreportpaperanalyquestiondiagknowledgeoverall`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportclasscompetitionindicator(params) {
  // 教研报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statunionresearchreport/getresearchreportclasscompetitionindicator`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportclasscompetitionscoredistribution(
  params
) {
  // 教研报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statunionresearchreport/getresearchreportclasscompetitionscoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportsubjectcontribution(params) {
  // 教研报告-学科贡献
  return request({
    url: `/report/statunionresearchreport/getresearchreportsubjectcontribution`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportfocusonstudentcritical(params) {
  // 教研报告-重点关注学生-临界生
  return request({
    url: `/report/statunionresearchreport/getresearchreportfocusonstudentcritical`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportfocusonstudenttrack(params) {
  // 教研报告-重点关注学生-跟踪生
  return request({
    url: `/report/statunionresearchreport/getresearchreportfocusonstudenttrack`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportfocusonstudentwave(params) {
  // 教研报告-重点关注学生-波动生
  return request({
    url: `/report/statunionresearchreport/getresearchreportfocusonstudentwave`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportfocusonstudenttoporbackward(params) {
  // 教研报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statunionresearchreport/getresearchreportfocusonstudenttoporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportdevelopanalysis(params) {
  // 教研报告-发展性分析V2
  return request({
    url: `/report/statunionresearchreport/getresearchreportdevelopanalysis/v2`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
// -----------------班主任报告----------------
export function Uniongetbanzhurenreporttopinfo(params) {
  // 获取班主任报告顶部数据
  return request({
    url: `/report/statunionbanzhurenreport/getbanzhurenreporttopinfo`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportclasscompetitiveOnline(params) {
  // 班主任报告-班级竞争力-上线情况
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportclasscompetitive/online`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportclasscompetitiveStudentlevel(params) {
  // 班主任报告-班级竞争力-学生等级
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportclasscompetitive/ratio`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportclasscompetitiveTop(params) {
  // 班主任报告-班级竞争力-前N名
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportclasscompetitive/topsection`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportclasscompetitiveOther(params) {
  // 班主任报告-班级竞争力-前N名
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportclasscompetitive/other`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportscoredistributionScore(params) {
  // 班主任报告-成绩分布-分数分布
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportscoredistribution/score`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportscoredistributionRank(params) {
  // 班主任报告-成绩分布-排名分布
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportscoredistribution/rank`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportsubjectAveragescore(params) {
  // 班主任报告-优劣势学科-平均分
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportsubject/averagescore`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportsubjectOnline(params) {
  // 班主任报告-优劣势学科-上线情况
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportsubject/online`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportsubjectRatio(params) {
  // 班主任报告 - 优劣势学科 - 比率;
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportsubject/ratio`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportsubjectTop(params) {
  // 班主任报告-优劣势学科-前N名
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportsubject/topsection`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportsubjectOther(params) {
  // 班主任报告-优劣势学科-其他指标
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportsubject/other`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportimportstudentCritical(params) {
  // 班主任报告-重点关注学生-临界生
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportimportstudent/critical`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportimportstudentWave(params) {
  // 班主任报告-重点关注学生-临界生
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportimportstudent/wave`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetbzrreportimportstudent(params) {
  // 班主任报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportimportstudent/toporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportimportstudentTracking(params) {
  // 班主任报告-重点关注学生-跟踪生
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportimportstudent/track`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportpaperanalysisQuestionorder(params) {
  // 班主任报告-试卷分析-应答情况分析-题目
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportpaperanaly/question`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportpaperanalysisKnowledgepoints(params) {
  // 班主任报告-试卷分析-应答情况分析-知识点
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportpaperanaly/knowledge`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportpaperanalysisResponsefeedback(params) {
  // 班主任报告-试卷分析-学生应答反馈
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportpaperanaly/student`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetbzrreportdevelopanalysisV2(params) {
  // 班主任报告-发展性分析V2
  return request({
    url: `/report/statunionbanzhurenreport/getbzrreportdevelopanalysis/v2`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
// ------------------- 任课教师---------------------
export function Uniongetrenkejiaoshireporttopinfo(params) {
  // 班主任报告-发展性分析V2
  return request({
    url: `/report/statunionrenkejiaoshireport/getrenkejiaoshireporttopinfo`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportclasscompetitiveOnline(params) {
  // 任课教师报告-班级竞争力-上线情况
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportclasscompetitive/online`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportclasscompetitiveRatio(params) {
  // 任课教师报告-班级竞争力-学生等级
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportclasscompetitive/ratio`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportclasscompetitiveTopsection(params) {
  // 任课教师报告-班级竞争力-前N名
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportclasscompetitive/topsection`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportclasscompetitiveOther(params) {
  // 任课教师报告-班级竞争力-其他指标
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportclasscompetitive/other`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportscoredistributionScore(params) {
  // 任课教师报告-成绩分布-分数分布
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportscoredistribution/score`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportscoredistributionRank(params) {
  // 任课教师报告-成绩分布-分数分布
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportscoredistribution/rank`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportimportstudentCritical(params) {
  // 任课教师报告-重点关注学生-临界生
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportimportstudent/critical`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportimportstudentWave(params) {
  // 任课教师报告-重点关注学生-波动生
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportimportstudent/wave`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportimportstudentToporbackward(params) {
  // 任课教师报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportimportstudent/toporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportimportstudentTrack(params) {
  // 任课教师报告-重点关注学生-跟踪生
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportimportstudent/track`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportdevelopanalysisV2(params) {
  // 任课教师报告-发展性分析V2
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportdevelopanalysis/v2`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportpaperanalyKnowledge(params) {
  // 任课教师报告-试卷分析-应答情况分析-知识点
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportpaperanaly/knowledge`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportpaperanalyQuestion(params) {
  // 任课教师报告-试卷分析-应答情况分析-题目
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportpaperanaly/question`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function UniongetrkjsreportpaperanalyStudent(params) {
  // 任课教师报告-试卷分析-学生应答反馈
  return request({
    url: `/report/statunionrenkejiaoshireport/getrkjsreportpaperanaly/student`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
// ---------------- 试卷讲评 ------------------
export function Uniongetprreportquestionanswer(params) {
  // 试卷讲评-题目应答情况
  return request({
    url: `/report/statunionpaperreviewreport/getprreportquestionanswer`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetprreportanalysis(params) {
  // 试卷讲评-讲评分析V2
  return request({
    url: `/report/statunionpaperreviewreport/getprreportanalysis/v2`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
// ---------学生报告 --------
export function unionTopData(params) {
  // 获取学生榜单顶部数据
  return request({
    url: `/report/statunionstudentreport/getstudentreportstudentlisttopinfo`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Unionstudentlistpage(params) {
  // 学生榜单分页查询
  return request({
    url: `/report/statunionstudentreport/studentlistpage`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Unionexportstudentscore(params) {
  // 导出
  return request({
    url: `/report/statunionstudentreport/exportstudentscore`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetstudentreporttopinfo(params) {
  // 获取学生报告顶部数据
  return request({
    url: `/report/statunionstudentreport/getstudentreporttopinfo`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetsrscoreoverview(params) {
  // 学生报告-成绩概述
  return request({
    url: `/report/statunionstudentreport/getsrscoreoverview`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetsrweak(params) {
  // 学生报告-优劣势学科
  return request({
    url: `/report/statunionstudentreport/getsrweak`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetsrlearningtrack(params) {
  // 学生报告-学情跟踪V2
  return request({
    url: `/report/statunionstudentreport/getsrlearningtrack/v2`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetsrknowledge(params) {
  // 学生报告-知识点
  return request({
    url: `/report/statunionstudentreport/getsrknowledge`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetsrquestion(params) {
  // 学生报告-知识点
  return request({
    url: `/report/statunionstudentreport/getsrquestion`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongettopinfo(params) {
  // 获取统计报告详情页顶部数据
  return request({
    url: `/report/statunionreportinfo/gettopinfo`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetschoolreportsimple(params) {
  // 获取校级报告简单图表
  return request({
    url: `/report/statunionreportinfo/getschoolreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetresearchreportsimple(params) {
  // 获取教研报告简单图表
  return request({
    url: `/report/statunionreportinfo/getresearchreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetbanzhurenreportsimple(params) {
  // 获取班主任报告简单图表
  return request({
    url: `/report/statunionreportinfo/getbanzhurenreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetrenkejiaoshireportsimple(params) {
  // 获取班主任报告简单图表
  return request({
    url: `/report/statunionreportinfo/getrenkejiaoshireportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetpaperreviewreportsimple(params) {
  // 获取试卷讲评简单图表
  return request({
    url: `/report/statunionreportinfo/getpaperreviewreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function Uniongetstudentreportsimple(params) {
  // 获取学生报告简单图表
  return request({
    url: `/report/statunionreportinfo/getstudentreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function unionPublish(params) {
  // 联考统计发布成绩
  return request({
    url: `/report/stat/publish`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function publishstudent(params) {
  // 联考统计发布成绩给学生
  return request({
    url: `/report/stat/publishstudent`,
    method: `post`,
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function newExamGetschoolreportsimple(params) {
  // 获取校级报告简单图表-联考新高考
  return request({
    url: `/report/statunionnewgaokaoreportinfo/getschoolreportsimple`,
    method: `get`,
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
